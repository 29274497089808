import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { useFullRangeModel } from './useFullRangeModel.mjs';

const isOnline = () => {
  return navigator.onLine
}

/**
 * Constructor of TFFaceMesh object
 * @constructor
 * */
const TFFaceMesh = function() {
  this.model = faceLandmarksDetection.createDetector(
    faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh,
    { 
      runtime: 'tfjs',
      detectorModelUrl: isOnline() ? 'https://tfhub.dev/mediapipe/tfjs-model/face_detection/full/1' : './models/detector/model.json',
      landmarkModelUrl: isOnline() ? undefined : './models/landmark/model.json'
    }
  );

  this.predictionReady = false;
  this.modelLoaded = false;
};

const _modelLoadingInProgress = { current: false, resolves: [] };

TFFaceMesh.prototype.loadModel = async function() {
  if (this.modelLoaded) return;
  
  if (_modelLoadingInProgress.current) {
    // add a promise to the list of promises to be resolved when the model is loaded
    const promise = new Promise((resolve) => {
      _modelLoadingInProgress.resolves.push(resolve);
    });

    return await promise;
  }
  
  _modelLoadingInProgress.current = true;

  try {
    this.model = await this.model;
    useFullRangeModel(this.model);
    this.modelLoaded = true;

    // resolve all promises that were added while the model was loading
    _modelLoadingInProgress.resolves.forEach((resolve) => resolve());
  } finally {
    _modelLoadingInProgress.current = false;
    _modelLoadingInProgress.resolves = [];
  }

  return;
}

// Global variable for face landmark positions array
TFFaceMesh.prototype.positionsArray = null;

/**
 * Isolates the two patches that correspond to the user's eyes
 * @param  {Object} video - the video element itself
 * @param  {Canvas} imageCanvas - canvas corresponding to the webcam stream
 * @param  {Number} width - of imageCanvas
 * @param  {Number} height - of imageCanvas
 * @return {Object} the two eye-patches, first left, then right eye
 */
TFFaceMesh.prototype.getEyePatches = async function(video, imageCanvas, width, height) {

  if (imageCanvas.width === 0) {
    return null;
  }

  // Load the MediaPipe facemesh model.
  const model = this.model;
  // useFullRangeModel(model);

  // Pass in a video stream (or an image, canvas, or 3D tensor) to obtain an
  // array of detected faces from the MediaPipe graph.
  const predictions = await model.estimateFaces(imageCanvas, {
    flipHorizontal: false,
  });

  if (predictions.length === 0){
    return false;
  }

  // Save positions to global variable
  this.positionsArray = predictions[0].keypoints;
  const prediction = predictions[0]
  // const positions = this.positionsArray;

  // const { scaledMesh } = predictions[0];

  // Keypoints indexes are documented at
  // https://github.com/tensorflow/tfjs-models/blob/118d4727197d4a21e2d4691e134a7bc30d90deee/face-landmarks-detection/mesh_map.jpg
  // https://stackoverflow.com/questions/66649492/how-to-get-specific-landmark-of-face-like-lips-or-eyes-using-tensorflow-js-face
  const [leftBBox, rightBBox] = [
    // left
    {
      eyeTopArc: [466, 388, 387, 386, 385, 384, 398].map(ind => prediction.keypoints[ind]),
      eyeBottomArc: [263, 249, 390, 373, 374, 380, 381, 382, 362].map(ind => prediction.keypoints[ind])
    },
    // right
    {
      eyeTopArc: [246, 161, 160, 159, 158, 157, 173].map(ind => prediction.keypoints[ind]),
      eyeBottomArc: [33, 7, 163, 144, 145, 153, 154, 155, 133].map(ind => prediction.keypoints[ind])
    },
  ].map(({ eyeTopArc, eyeBottomArc }) => {
    const topLeftOrigin = {
      x: Math.round(Math.min(...eyeTopArc.map(v => v.x))),
      y: Math.round(Math.min(...eyeTopArc.map(v => v.y))),
    };
    const bottomRightOrigin = {
      x: Math.round(Math.max(...eyeBottomArc.map(v => v.x))),
      y: Math.round(Math.max(...eyeBottomArc.map(v => v.y))),
    };

    return {
      origin: topLeftOrigin,
      width: bottomRightOrigin.x - topLeftOrigin.x,
      height: bottomRightOrigin.y - topLeftOrigin.y,
    }
  });
  var leftOriginX = leftBBox.origin.x;
  var leftOriginY = leftBBox.origin.y;
  var leftWidth = leftBBox.width;
  var leftHeight = leftBBox.height;
  var rightOriginX = rightBBox.origin.x;
  var rightOriginY = rightBBox.origin.y;
  var rightWidth = rightBBox.width;
  var rightHeight = rightBBox.height;

  if (leftWidth === 0 || rightWidth === 0){
    console.log('an eye patch had zero width');
    return null;
  }

  if (leftHeight === 0 || rightHeight === 0){
    console.log('an eye patch had zero height');
    return null;
  }

  // Start building object to be returned
  var eyeObjs = {};

  var leftImageData = imageCanvas.getContext('2d').getImageData(leftOriginX, leftOriginY, leftWidth, leftHeight);
  eyeObjs.left = {
    patch: leftImageData,
    imagex: leftOriginX,
    imagey: leftOriginY,
    width: leftWidth,
    height: leftHeight
  };

  var rightImageData = imageCanvas.getContext('2d').getImageData(rightOriginX, rightOriginY, rightWidth, rightHeight);
  eyeObjs.right = {
    patch: rightImageData,
    imagex: rightOriginX,
    imagey: rightOriginY,
    width: rightWidth,
    height: rightHeight
  };

  this.predictionReady = true;

  return eyeObjs;
};

/**
 * Returns the positions array corresponding to the last call to getEyePatches.
 * Requires that getEyePatches() was called previously, else returns null.
 */
TFFaceMesh.prototype.getPositions = function () {
  return this.positionsArray;
}

/**
 * Reset the tracker to default values
 */
TFFaceMesh.prototype.reset = function(){
  console.log( "Unimplemented; Tracking.js has no obvious reset function" );
}

/**
 * Draw TF_FaceMesh_Overlay
 */
TFFaceMesh.prototype.drawFaceOverlay = function(ctx, keypoints){
  // If keypoints is falsy, don't do anything
  if (keypoints) {
    ctx.fillStyle = '#32EEDB';
    ctx.strokeStyle = '#32EEDB';
    ctx.lineWidth = 0.5;

    for (let i = 0; i < keypoints.length; i++) {
      const x = keypoints[i][0];
      const y = keypoints[i][1];

      ctx.beginPath();
      ctx.arc(x, y, 1 /* radius */, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.fill();
    }
  }
}

/**
 * The TFFaceMesh object name
 * @type {string}
 */
TFFaceMesh.prototype.name = 'TFFaceMesh';

export default TFFaceMesh;
